import React, {
    useMemo
} from "react";

const startYear = 2023;

function Copyright() {
    const yearString = useMemo(() => {
        const currentYear = new Date().getFullYear();
        if(currentYear === startYear) {
            return startYear;
        }
        return `${startYear} - ${currentYear}`;
    }, []);
    return (
        <React.Fragment>
            Copyright &copy; { yearString } GridSense B.V. All rights reserved.
        </React.Fragment>
    )
}

export default React.memo(Copyright);
