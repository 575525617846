import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import SubscriptionTransactionCard from "./components/SubscriptionTransactionCard";
import SettingsSubscriptionTransactionModal from "./modal/SettingsSubscriptionTransactionModal";

function Transactions() {
    const [transactions, setTransactions] = useState(null);
    const [error, setError] = useState(null);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [modalTransaction, setModalTransaction] = useState(null);

    const getTransactions = useCallback(async () => {
        setError(null);
        setTransactions(null);
        try {
            const response = await axios.get("/getAllSubscriptionTransactions");
            setTransactions(response.data.subscriptionTransactions);
        } catch (requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        }
    }, []);

    const onClickMoreInfo = useCallback((transaction) => {
        setModalTransaction(transaction);
        setShowTransactionModal(true);
    }, []);
    const onUpdatedTransaction = useCallback((newTransaction) => {
        if(!newTransaction) {
            return;
        }
        setModalTransaction((oldTransaction) => {
            if(oldTransaction?.id === newTransaction.id) {
                return newTransaction;
            }
            return oldTransaction;
        });
        setTransactions((oldTransactions) => {
            return oldTransactions.map((oldTransaction) => {
                if(oldTransaction.id === newTransaction.id) {
                    return newTransaction;
                }
                return oldTransaction;
            });
        });
    }, []);

    useEffect(() => {
        getTransactions();
    }, [getTransactions]);

    return (
        <React.Fragment>
            <Helmet title="Transacties"/>
            <Title preTitle="Overzicht">
                Transacties
            </Title>

            <SettingsSubscriptionTransactionModal
                show={ showTransactionModal }
                transaction={ modalTransaction }
                handleClose={ () => setShowTransactionModal(false) }
                onUpdatedTransaction={ onUpdatedTransaction }
                admin
            />

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !transactions ? (
                <Loading/>
            ) : transactions.map((transaction) => (
                <SubscriptionTransactionCard
                    key={ transaction.id }
                    transaction={ transaction }
                    onClickMoreInfo={ onClickMoreInfo }
                    admin
                />
            ))}
        </React.Fragment>
    );
}

export default React.memo(Transactions);
