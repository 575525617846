import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import SettingsSubscriptionTransactionModal from "../transactions/modal/SettingsSubscriptionTransactionModal";
import SubscriptionTransactionCard from "../transactions/components/SubscriptionTransactionCard";

function SettingsInvoices() {
    const [transactions, setTransactions] = useState(null);
    const [error, setError] = useState(null);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [modalTransaction, setModalTransaction] = useState(null);

    useEffect(() => {
        setTransactions(null);
        setError(null);
        axios.get("/getSubscriptionTransactions")
            .then((response) => {
                if(response.data.valid) {
                    setTransactions(response.data.subscriptionTransactions);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            });
    }, []);

    const onShowTransactionModal = useCallback((transaction) => {
        setModalTransaction(transaction);
        setShowTransactionModal(true);
    }, []);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!transactions) {
        return (
            <Skeleton height={ 150 } count={ 5 }/>
        )
    }
    if(transactions.length === 0) {
        return (
            <div className="text-center">
                <h1><i className="fa-solid fa-receipt"/></h1>
                <h4>Geen facturen</h4>
                <p>Er zijn nog geen transacties uitgevoerd.</p>
            </div>
        );
    }
    return (
        <React.Fragment>
            <SettingsSubscriptionTransactionModal
                show={ showTransactionModal }
                transaction={ modalTransaction }
                handleClose={ () => setShowTransactionModal(false) }
            />
            { transactions.map((transaction) => (
                <SubscriptionTransactionCard
                    key={ transaction.id }
                    transaction={ transaction }
                    onClickMoreInfo={ onShowTransactionModal }
                />
            ))}
        </React.Fragment>
    );
}

export default React.memo(SettingsInvoices);
