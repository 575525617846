import React from "react";
import {
    TabBar,
    TabBarItem
} from "@zandor300/backoffice-framework";

function SubscriptionsTabBar(props) {
    return (
        <TabBar {...props}>
            <TabBarItem to="/subscriptions">
                Overzicht
            </TabBarItem>
            <TabBarItem to="/subscriptions/installations-without">
                Installaties zonder abonnement
            </TabBarItem>
        </TabBar>
    );
}

export default React.memo(SubscriptionsTabBar);
