import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartDateSwitcher from "../../../energy-prices/components/ChartDateSwitcher";
import ChartLoading from "../../../../components/charts/ChartLoading";
import ChartNoData from "../../../../components/charts/ChartNoData";
import ChartCard from "../../../../components/charts/ChartCard";
import HistoryResolution from "../../../../utilities/HistoryResolution";

function EnergyMeterHistoryModal({ energyMeter, show, handleClose }) {
    const [energyMeterId, setEnergyMeterId] = useState(null);
    const [date, setDate] = useState(moment().tz("Europe/Amsterdam", true));
    const [historyPower, setHistoryPower] = useState(null);
    const [historyEnergy, setHistoryEnergy] = useState(null);
    const [error, setError] = useState(null);

    const onDateChange = useCallback((newDate) => {
        setDate(newDate);
    }, []);
    const chartDateRange = useMemo(() => {
        return {
            startDate: date.clone().startOf("day"),
            endDate: date.clone().endOf("day")
        };
    }, [date]);

    useEffect(() => {
        if(!show) {
            return;
        }
        onDateChange(moment().tz("Europe/Amsterdam", true));
    }, [show, onDateChange]);
    useEffect(() => {
        setEnergyMeterId(energyMeter.id);
    }, [energyMeter]);

    const refreshHistoryPower = useCallback(async () => {
        setHistoryPower(null);
        setError(null);
        if(!energyMeterId) {
            return;
        }
        try {
            const response = await axios.post("/getEnergyMeterHistory", {
                energyMeterId: energyMeterId,
                startDate: chartDateRange.startDate.toISOString(),
                endDate: chartDateRange.endDate.toISOString(),
                resolution: HistoryResolution.FIVE_MINUTES.value,
            });
            setHistoryPower(response.data.history);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [energyMeterId, chartDateRange]);
    const refreshHistoryEnergy = useCallback(async () => {
        setHistoryEnergy(null);
        setError(null);
        if(!energyMeterId) {
            return;
        }
        try {
            const response = await axios.post("/getEnergyMeterHistory", {
                energyMeterId: energyMeterId,
                startDate: chartDateRange.startDate.toISOString(),
                endDate: chartDateRange.endDate.toISOString(),
                resolution: HistoryResolution.ONE_HOUR.value,
            });
            setHistoryEnergy(response.data.history);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [energyMeterId, chartDateRange]);

    useEffect(() => {
        if(!show) {
            return;
        }
        refreshHistoryPower();
        refreshHistoryEnergy();
    }, [show, refreshHistoryPower, refreshHistoryEnergy]);

    const dataPower = useMemo(() => {
        if(!historyPower) {
            return null;
        }
        if(historyPower.length === 0 || historyPower.filter(({ energyImport, energyExport }) => {
            return energyImport > 0 || energyExport > 0;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "Import",
                data: historyPower.map(({ date, energyImport }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: energyImport * HistoryResolution.FIVE_MINUTES.adjustEnergy,
                })),
            },
            {
                label: "Export",
                data: historyPower.map(({ date, energyExport }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: -energyExport * HistoryResolution.FIVE_MINUTES.adjustEnergy,
                })),
            }
        ];
    }, [historyPower]);
    const dataEnergy = useMemo(() => {
        if(!historyEnergy) {
            return null;
        }
        if(historyEnergy.length === 0 || historyEnergy.filter(({ energyImport, energyExport }) => {
            return energyImport > 0 || energyExport > 0;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "Import",
                data: historyEnergy.map(({ date, energyImport }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: energyImport * HistoryResolution.ONE_HOUR.adjustEnergy,
                })),
            },
            {
                label: "Export",
                data: historyEnergy.map(({ date, energyExport }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: -energyExport * HistoryResolution.ONE_HOUR.adjustEnergy,
                })),
            }
        ];
    }, [historyEnergy]);
    const primaryAxisPower = useMemo(() => ({
        getValue: (datum) => moment(datum.key).toDate(),
        formatters: {
            scale: (key) => moment(key).format("HH:mm"),
        },
        min: moment(chartDateRange.startDate).toDate(),
        max: moment(chartDateRange.endDate).toDate(),
    }), [chartDateRange]);
    const secondaryAxesPower = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${(value * 0.001).toFixed(2)} kW`,
            tooltip: (value) => `${(value * 0.001).toFixed(2)} kW`,
        },
        stacked: true,
    }], []);
    const primaryAxisEnergy = useMemo(() => ({
        getValue: (datum) => moment(datum.key).format("HH:00")
    }), []);
    const secondaryAxesEnergy = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${(value * 0.001).toFixed(2)} kWh`,
            tooltip: (value) => `${Math.abs(value * 0.001).toFixed(2)} kWh`,
        },
    }], []);

    return (
        <Modal show={ show } onHide={ handleClose } size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Energiemeter data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-3">
                    <div className="card-body">
                        <ChartDateSwitcher
                            date={ date }
                            setDate={ onDateChange }
                        />
                    </div>
                </div>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : (
                    <React.Fragment>
                        <ChartCard title="Import / Export vermogen">
                            { !historyPower ? (
                                <ChartLoading/>
                            ) : historyPower.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <Chart
                                    options={{
                                        data: dataPower,
                                        primaryAxis: primaryAxisPower,
                                        secondaryAxes: secondaryAxesPower,
                                    }}
                                />
                            )}
                        </ChartCard>
                        <ChartCard title="Import / Export energie">
                            { !historyEnergy ? (
                                <ChartLoading/>
                            ) : historyEnergy.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <Chart
                                    options={{
                                        data: dataEnergy,
                                        primaryAxis: primaryAxisEnergy,
                                        secondaryAxes: secondaryAxesEnergy,
                                    }}
                                />
                            )}
                        </ChartCard>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(EnergyMeterHistoryModal);
