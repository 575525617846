import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment-timezone";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartLoading from "../components/charts/ChartLoading";
import ChartNoData from "../components/charts/ChartNoData";

function ChartBatteryHistory({ startDate, endDate, installationId }) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!startDate || !endDate || !installationId) {
            return;
        }
        const startDateFormat = startDate.tz("Europe/Amsterdam").toISOString();
        const endDateFormat = endDate.tz("Europe/Amsterdam").toISOString();
        setData(null);
        setError(null);
        const parameters = {
            installationId,
            startDate: startDateFormat,
            endDate: endDateFormat
        }
        axios.post("/getBatteryHistory", parameters)
            .then((response) => {
                if(response.data.valid) {
                    if(!response.data.soeHistory || response.data.soeHistory.length === 0) {
                        setData([]);
                        return;
                    }
                    setData([{
                        label: "SoE",
                        data: Object.entries(response.data.soeHistory).map(([date, soe]) => ({
                            key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                            value: soe
                        }))
                    }]);
                } else {
                    setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            });
    }, [startDate, endDate, installationId]);

    const primaryAxis = useMemo(() => ({
        getValue: (datum) => moment(datum.key).toDate(),
        formatters: {
            scale: (key) => moment(key).format("HH:mm")
        },
        min: moment(startDate).toDate(),
        max: moment(endDate).toDate()
    }), [startDate, endDate]);
    const secondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${value}%`,
            tooltip: (value) => `${value}%`
        },
        stacked: true,
        min: 0,
        max: 100
    }], []);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes
            }}
        />
    )
}

export default React.memo(ChartBatteryHistory);
