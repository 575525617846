import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Alert
} from "react-bootstrap";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageColumnFooter,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

import Helmet from "../../components/Helmet";
import Copyright from "./Copyright";
import Logo from "../../components/Logo";
import {Link} from "react-router-dom";

function Login() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onError = useMemo(() => {
        return (errorMessage) => {
            setError(errorMessage);
            setLoading(false);
        }
    }, []);
    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            setLoading(true);
            authenticatedUserContext.login({ email, password }, onError);
        }
    }, [authenticatedUserContext, email, password, onError]);

    return (
        <AuthenticationPage>
            <Helmet/>
            <AuthenticationPageColumn>
                <Logo className="login-logo"/>
                <h3 className="text-center text-muted mt-0 mb-4">
                    Inloggen
                </h3>
                { error && (
                    <Alert variant="danger">
                        { error }
                    </Alert>
                )}
                <form onSubmit={ onSubmit }>
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            disabled={ loading }
                            value={ email }
                            onChange={(event) => setEmail(event.target.value) }
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Wachtwoord"
                            disabled={ loading }
                            value={ password }
                            onChange={(event) => setPassword(event.target.value) }
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <div className="flex-grow-1">
                            <Link to="/forgot-password" style={{ fontSize: "0.9rem" }}>
                                Wachtwoord vergeten
                            </Link>
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Inloggen
                        </button>
                    </div>
                </form>

                <AuthenticationPageColumnFooter>
                    <a href="https://gridsense.nl">
                        GridSense website
                    </a>
                </AuthenticationPageColumnFooter>
            </AuthenticationPageColumn>

            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    )
}

export default React.memo(Login);
