import React, {
    useCallback, useMemo
} from "react";
import {
    Button
} from "react-bootstrap";

import InfoCardRow from "../../../components/InfoCardRow";
import TagPill from "../../../components/TagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import EuroFormatter from "../../../components/formatters/EuroFormatter";

function SubscriptionTransactionCard({ transaction, onClickMoreInfo, admin = false }) {
    const onClickInternal = useCallback(() => {
        onClickMoreInfo?.(transaction);
    }, [transaction, onClickMoreInfo]);

    const columnClassName = "col-md-6 col-lg-4 col-xl-3";

    const cardClassNames = useMemo(() => {
        const classNames = ["card", "mb-2"];
        if(admin && transaction.canCreateInvoice) {
            classNames.push("border-primary");
        }
        if(admin && onClickMoreInfo) {
            classNames.push("pointer-cursor");
        }
        return classNames.join(" ");
    }, [admin, transaction, onClickMoreInfo]);

    return (
        <div className={ cardClassNames } onClick={ admin && onClickInternal }>
            <div className="card-body">
                <h5 className="card-title">
                    {transaction.type === "first" ? (
                        "Koppelingstransactie"
                    ) : transaction.type === "recurring" ? (
                        "Abonnementstransactie"
                    ) : (
                        "Onbekende transactie"
                    )}
                </h5>
                <div className="row">
                    { admin && (
                        <div className={columnClassName}>
                            <InfoCardRow title="Gebruiker">
                                { transaction.user?.name }
                            </InfoCardRow>
                        </div>
                    )}
                    <div className={columnClassName}>
                        <InfoCardRow title="Status">
                            <TagPill color={transaction.status.color}>
                                {transaction.status.name}
                            </TagPill>
                        </InfoCardRow>
                    </div>
                    <div className={columnClassName}>
                        <InfoCardRow title="Datum">
                            <DateFormatter
                                date={transaction.paidDate ? transaction.paidDate : transaction.createdDate}
                            />
                        </InfoCardRow>
                    </div>
                    <div className={columnClassName}>
                        <InfoCardRow title="Totale kosten">
                            <EuroFormatter price={transaction.price}/>
                        </InfoCardRow>
                    </div>
                </div>
                { !admin && onClickMoreInfo && (
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="primary"
                            size="sm"
                            className="mt-2"
                            onClick={onClickInternal}
                        >
                            Meer informatie
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(SubscriptionTransactionCard);
