import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import {
    LoadingLongOperation
} from "@zandor300/backoffice-framework";

function InstallationGatewayRestartSoftwareModal({ show, handleClose, gateway }) {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [progressBarFinished, setProgressBarFinished] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!show) {
            return;
        }
        setLoading(false);
        setSuccess(false);
        setError(null);
        setProgressBarFinished(false);
    }, [show]);

    const onSubmit = useCallback(async () => {
        setLoading(true);
        setSuccess(false);
        setError(null);
        try {
            await axios.post("/restartGatewaySoftware", { uuid: gateway.uuid });
            setSuccess(true);
        } catch (error) {
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [gateway]);

    const onProgressBarFinish = useCallback(() => {
        setProgressBarFinished(true);
    }, []);

    const progressBarLoading = useMemo(() => {
        return loading || (success && !progressBarFinished);
    }, [loading, success, progressBarFinished]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Gateway software herstarten</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { !success || progressBarLoading ? (
                    <React.Fragment>
                        <p className="mb-0">
                            Weet je zeker dat je de software van de gateway <b>{ gateway?.uuid }</b> wilt herstarten?
                        </p>
                        { progressBarLoading && (
                            <div className="mt-3">
                                <LoadingLongOperation
                                    finished={ success }
                                    estimatedFinishSeconds={ 8 }
                                    onFinished={ onProgressBarFinish }
                                />
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <div className="text-center">
                        <h1 className="text-success"><i className="fa-solid fa-circle-check"/></h1>
                        <h3>Gateway Software wordt herstart...</h3>
                        <p className="mb-0">Dit kan ongeveer een minuut duren.</p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                { !success ? (
                    <React.Fragment>
                        <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                            Annuleren
                        </Button>
                        <Button variant="primary" onClick={ onSubmit } disabled={ loading }>
                            { progressBarLoading && (
                                <Spinner animation="border" size="sm" className="mr-2"/>
                            )}
                            Herstart
                        </Button>
                    </React.Fragment>
                ) : (
                    <Button variant="secondary" onClick={ handleClose }>
                        Sluiten
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(InstallationGatewayRestartSoftwareModal);
