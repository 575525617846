import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import SubscriptionsTabBar from "./components/SubscriptionsTabBar";
import Loading from "../../components/Loading";
import DateFormatter from "../../components/formatters/DateFormatter";

function SubscriptionsInstallationsWithout() {
    const [installations, setInstallations] = useState(null);
    const [error, setError] = useState(null);

    const refreshInstallations = useCallback(async () => {
        setError(null);
        try {
            const response = await axios.post("/getInstallationsWithoutSubscription");
            setInstallations(response.data.installations);
        } catch (requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, []);

    useEffect(() => {
        refreshInstallations();
    }, [refreshInstallations]);

    return (
        <React.Fragment>
            <Helmet title="Installaties zonder abonnement"/>
            <Title preTitle="Overzicht" noBottom>
                Installaties zonder abonnement
            </Title>
            <SubscriptionsTabBar noBottom={!!installations}/>

            {error ? (
                <Alert variant="danger">{error}</Alert>
            ) : !installations ? (
                <Loading/>
            ) : (
                <Table hover>
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-left">Installatie</th>
                        <th className="text-left">Adres</th>
                        <th className="text-right">Aanmaakdatum</th>
                    </tr>
                    </thead>
                    <tbody>
                    {installations.map((installation) => (
                        <tr key={installation.id}>
                            <td className="text-center">{installation.id}</td>
                            <td className="text-left">{installation.name}</td>
                            <td className="text-left">{installation.address}, {installation.city}</td>
                            <td className="text-right">
                                <DateFormatter date={installation.creationDate} format="DD-MM-YYYY"/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    );
}

export default React.memo(SubscriptionsInstallationsWithout);
