import React, {
    useCallback,
    useState
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import NumberFormatter from "../../../../../components/formatters/NumberFormatter";
import DateFormatter from "../../../../../components/formatters/DateFormatter";
import BatteryHistoryModal from "../BatteryHistoryModal";
import DeviceIcon from "../../../../../components/DeviceIcon";
import DeviceCardRow from "./components/DeviceCardRow";

export function InstallationBatteryCard({ battery, onClickAddDevice, allowHistory, disabled }) {
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    const handleCloseHistoryModal = useCallback(() => {
        setShowHistoryModal(false);
    }, []);

    return (
        <div className="card mb-3">
            { allowHistory && (
                <BatteryHistoryModal
                    battery={ battery }
                    show={ showHistoryModal }
                    handleClose={ handleCloseHistoryModal }
                />
            )}
            <div className="card-body">
                <div className="d-flex flex-row align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { battery.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { battery.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { battery.serialNumber }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3 d-flex flex-row align-items-center">
                        { allowHistory && (
                            <OverlayTrigger overlay={
                                <Tooltip id={ `battery-history-${battery.id}` }>
                                    Bekijk historie
                                </Tooltip>
                            }>
                                <Button
                                    variant="link"
                                    className="py-0"
                                    onClick={ () => setShowHistoryModal(true) }
                                >
                                    <i className="fa-solid fa-history fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                        )}
                        <DeviceIcon deviceType="battery" soe={ battery.soe }/>
                    </div>
                </div>
                <DeviceCardRow title="Laadtoestand">
                    { battery.soe !== null ? (
                        <React.Fragment>
                            <NumberFormatter
                                number={ battery.soe }
                                maximumFractionDigits={ 2 }
                            />%
                        </React.Fragment>
                    ) : "Unknown"}
                </DeviceCardRow>
                <DeviceCardRow title="Gezondheid">
                    { battery.soh !== null ? (
                        <React.Fragment>
                            <NumberFormatter
                                number={ battery.soh }
                                maximumFractionDigits={ 0 }
                            />%
                        </React.Fragment>
                    ) : "Unknown" }
                </DeviceCardRow>
                <DeviceCardRow title="DC Power">
                    { battery.powerDc !== null ? (
                        <React.Fragment>
                            <NumberFormatter
                                number={ battery.powerDc }
                                maximumFractionDigits={ 1 }
                            /> W
                        </React.Fragment>
                    ) : "Unknown" }
                </DeviceCardRow>
                <DeviceCardRow title="Status">
                    { battery.powerDc !== null ? (
                        battery.powerDc === 0 ? "Standby" : battery.powerDc > 0 ? "Laden" : "Ontladen"
                    ) : "Unknown" }
                </DeviceCardRow>
                <DeviceCardRow title="Capaciteit">
                    { battery.ratedEnergy !== null ? (
                        <React.Fragment>
                            <NumberFormatter
                                number={ battery.ratedEnergy / 1000 }
                                maximumFractionDigits={ 2 }
                            /> kWh
                        </React.Fragment>
                    ) : "Unknown" }
                </DeviceCardRow>
                <DeviceCardRow title="Laatst gezien">
                    <DateFormatter date={ battery.lastSeenDate }/>
                </DeviceCardRow>
                { onClickAddDevice && battery.installationId === null && (
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => onClickAddDevice("battery", battery.id) }
                            disabled={ disabled }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Batterij toevoegen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

function InstallationBatteryCards({ batteries, onClickAddDevice, disabled }) {
    return batteries.map((battery) => (
        <InstallationBatteryCard
            key={battery.id}
            battery={battery}
            onClickAddDevice={onClickAddDevice}
            disabled={disabled}
        />
    ))
}

export default React.memo(InstallationBatteryCards);
