import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import InfoCardRow from "../../../components/InfoCardRow";
import TagPill from "../../../components/TagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import EuroFormatter from "../../../components/formatters/EuroFormatter";
import IBANFormatter from "../../../components/formatters/IBANFormatter";

function SettingsSubscriptionTransactionModal({ show, handleClose, transaction, admin, onUpdatedTransaction }) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const createInvoice = useCallback(async () => {
        if(!transaction || !transaction.canCreateInvoice) {
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post("/createSubscriptionTransactionInvoice", {
                subscriptionTransactionId: transaction.id
            });
            onUpdatedTransaction?.(response.data.subscriptionTransaction);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [transaction, onUpdatedTransaction]);

    if(!transaction) {
        return null;
    }
    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>
                    { transaction.type === "first" ? (
                        "Koppelingstransactie"
                    ) : transaction.type === "recurring" && (
                        "Abonnementstransactie"
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <InfoCardRow title="Status" className="mb-3">
                    <TagPill color={ transaction.status.color }>
                        { transaction.status.name }
                    </TagPill>
                </InfoCardRow>
                <InfoCardRow title="Datum" className="mb-3">
                    <DateFormatter date={ transaction.paidDate ? transaction.paidDate : transaction.createdDate }/>
                </InfoCardRow>
                <InfoCardRow title="Totale kosten" className="mb-3">
                    <EuroFormatter price={ transaction.price }/>
                </InfoCardRow>
                { transaction.details?.consumerName && (
                    <InfoCardRow title="Rekeningnaam" className="mb-3">
                        { transaction.details.consumerName }
                    </InfoCardRow>
                )}
                { transaction.details?.consumerAccount && (
                    <InfoCardRow title="IBAN" className="mb-3">
                        <IBANFormatter iban={ transaction.details.consumerAccount }/>
                    </InfoCardRow>
                )}
                { admin && (
                    <React.Fragment>
                        <InfoCardRow title="Factuur" className="mb-3">
                            { transaction.invoiceAvailable ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="text-success flex-grow-1">
                                        <i className="fa-solid fa-check-circle"/> Factuur aangemaakt
                                    </div>
                                    <div>
                                        <Button variant="primary" size="sm" disabled={ loading }>
                                            Download
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="text-danger flex-grow-1">
                                        <i className="fa-solid fa-times-circle"/> Factuur niet aangemaakt
                                    </div>
                                    { transaction.canCreateInvoice && (
                                        <div>
                                            <Button variant="primary" size="sm" onClick={ createInvoice } disabled={ loading }>
                                                { loading && (
                                                    <Spinner animation="border" size="sm" className="mr-1"/>
                                                )}
                                                Factuur aanmaken
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </InfoCardRow>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(SettingsSubscriptionTransactionModal);
