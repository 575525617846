import React, {
    useMemo
} from "react";
import moment from "moment";

import InfoCardRow from "../../../components/InfoCardRow";
import NumberFormatter from "../../../components/formatters/NumberFormatter";
import EuroFormatter from "../../../components/formatters/EuroFormatter";

function SubscriptionStatistics({ subscriptions }) {
    const statistics = useMemo(() => {
        if(!subscriptions) {
            return null;
        }
        const statistics = {
            total: subscriptions.length,
            active: 0,
            inactive: 0,
            currentMonthlyRevenue: 0,
        };
        const now = moment();
        subscriptions.forEach((subscription) => {
            if(subscription.endDate) {
                statistics.inactive++;
                return;
            }
            statistics.active++;
            const startDate = moment(subscription.startDate);
            if(startDate > now) {
                return;
            }
            statistics.currentMonthlyRevenue += subscription.intervalPrice / subscription.intervalMonths;
        });
        statistics.currentMonthlyRevenue /= 1.21;
        return statistics;
    }, [subscriptions]);

    if(!statistics) {
        return null;
    }
    return (
        <div className="card mb-4">
            <div className="card-body">
                <h5 className="card-title">Statistieken</h5>
                <div className="row">
                    <div className="col-md-6 col-lg-4 col-xl-3">
                        <InfoCardRow title="Totaal">
                            <NumberFormatter number={statistics.total}/>
                        </InfoCardRow>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3">
                        <InfoCardRow title="Actief">
                            <NumberFormatter number={statistics.active}/>
                        </InfoCardRow>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3">
                        <InfoCardRow title="Inactief">
                            <NumberFormatter number={statistics.inactive}/>
                        </InfoCardRow>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3">
                        <InfoCardRow title="Huidige maandelijkse omzet">
                            <EuroFormatter price={statistics.currentMonthlyRevenue}/> <small className="text-muted">(excl. BTW)</small>
                        </InfoCardRow>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(SubscriptionStatistics);
