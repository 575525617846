import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";

import Subscriptions from "./Subscriptions";
import SubscriptionsInstallationsWithout from "./SubscriptionsInstallationsWithout";

function SubscriptionsSwitch() {
    return (
        <Switch>
            <Route path="/subscriptions" exact component={Subscriptions}/>
            <Route path="/subscriptions/installations-without" component={SubscriptionsInstallationsWithout}/>
        </Switch>
    );
}

export default React.memo(SubscriptionsSwitch);
