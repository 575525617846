import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import DateFormatter from "../../components/formatters/DateFormatter";
import SubscriptionStatistics from "./components/SubscriptionStatistics";
import EuroFormatter from "../../components/formatters/EuroFormatter";
import SubscriptionsTabbar from "./components/SubscriptionsTabBar";

function Subscriptions() {
    const [subscriptions, setSubscriptions] = useState(null);
    const [error, setError] = useState(null);

    const getSubscriptions = useCallback(async () => {
        setError(null);
        setSubscriptions(null);
        try {
            const response = await axios.get("/getAllSubscriptions");
            setSubscriptions(response.data.subscriptions);
        } catch (requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        }
    }, []);

    useEffect(() => {
        getSubscriptions();
    }, [getSubscriptions]);


    return (
        <React.Fragment>
            <Helmet title="Abonnementen"/>
            <Title preTitle="Overzicht" noBottom>
                Abonnementen
            </Title>
            <SubscriptionsTabbar/>

            {error ? (
                <Alert variant="danger">{error}</Alert>
            ) : !subscriptions ? (
                <Loading/>
            ) : (
                <React.Fragment>
                    <SubscriptionStatistics subscriptions={subscriptions}/>
                    <Table hover>
                        <thead>
                        <tr>
                            <th className="align-middle text-center">#</th>
                            <th className="align-middle text-left">Naam</th>
                            <th className="align-middle text-left">Installatie</th>
                            <th className="align-middle text-left">Betalende gebruiker</th>
                            <th className="align-middle text-left">Periode</th>
                            <th className="align-middle text-center">Start datum</th>
                            <th className="align-middle text-center">Eind datum</th>
                            <th className="align-middle text-right">Periode prijs</th>
                        </tr>
                        </thead>
                        <tbody>
                        { subscriptions.map((subscription) => {
                            const url = `/subscription/${subscription.id}`;
                            return (
                                <tr key={ subscription.id } className="table-clickable-row">
                                    <th className="align-middle text-center">
                                        <Link to={ url }>
                                            { subscription.id }
                                        </Link>
                                    </th>
                                    <td className="align-middle text-left">
                                        <Link to={ url }>
                                            { subscription.name }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-left">
                                        <Link to={`/installation/${subscription.installation?.id ?? 0}`}>
                                            { subscription.installation?.name ?? "Geen installatie" }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-left">
                                        <Link to={ url }>
                                            { subscription.paymentUser?.name ?? "Geen betalende gebruiker" }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-left">
                                        <Link to={ url }>
                                            { subscription.intervalMonths } maand{ subscription.intervalMonths > 1 ? "en" : "" }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-center">
                                        <Link to={ url }>
                                            <DateFormatter date={ subscription.startDate } format="DD-MM-YYYY"/>
                                        </Link>
                                    </td>
                                    <td className="align-middle text-center">
                                        <Link to={ url }>
                                            { subscription.endDate ? (
                                                <DateFormatter date={ subscription.endDate } format="DD-MM-YYYY"/>
                                            ) : (
                                                <span className="text-success">Actief</span>
                                            ) }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right">
                                        <Link to={ url }>
                                            <EuroFormatter price={ subscription.intervalPrice }/>
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(Subscriptions);
