import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartDateSwitcher from "../../../energy-prices/components/ChartDateSwitcher";
import ChartLoading from "../../../../components/charts/ChartLoading";
import ChartNoData from "../../../../components/charts/ChartNoData";
import ChartCard from "../../../../components/charts/ChartCard";

function BatteryHistoryModal({ battery, show, handleClose }) {
    const [batteryId, setBatteryId] = useState(null);
    const [date, setDate] = useState(moment().tz("Europe/Amsterdam", true));
    const [history, setHistory] = useState(null);
    const [error, setError] = useState(null);

    const onDateChange = useCallback((newDate) => {
        setDate(newDate);
    }, []);
    const chartDateRange = useMemo(() => {
        return {
            startDate: date.clone().startOf("day"),
            endDate: date.clone().endOf("day")
        };
    }, [date]);

    useEffect(() => {
        if(!show) {
            return;
        }
        onDateChange(moment().tz("Europe/Amsterdam", true));
    }, [show, onDateChange]);
    useEffect(() => {
        setBatteryId(battery.id);
    }, [battery]);
    const refreshHistory = useCallback(async () => {
        setHistory(null);
        setError(null);
        try {
            const response = await axios.post("/getBatteryHistory", {
                batteryId,
                startDate: chartDateRange.startDate.toISOString(),
                endDate: chartDateRange.endDate.toISOString()
            });
            setHistory(response.data.history);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [batteryId, chartDateRange]);
    useEffect(() => {
        if(!show) {
            return;
        }
        refreshHistory();
    }, [show, refreshHistory]);

    const chargeDischargeData = useMemo(() => {
        if(!history) {
            return null;
        }
        if(history.length === 0 || history.filter(({ energyCharged, energyDischarged }) => {
            return energyCharged > 0 || energyDischarged > 0;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "Geladen",
                data: history.map(({ date, energyCharged }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: energyCharged * (60 / 5)
                }))
            },
            {
                label: "Ontladen",
                data: history.map(({ date, energyDischarged }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: -energyDischarged * (60 / 5)
                }))
            }
        ];
    }, [history]);
    const soeData = useMemo(() => {
        if(!history) {
            return null;
        }
        if(history.length === 0 || history.filter(({ soe }) => {
            return soe !== null;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "Laadtoestand",
                data: history.map(({ date, soe }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: soe
                }))
            }
        ];
    }, [history]);
    const primaryAxis = useMemo(() => ({
        getValue: (datum) => moment(datum.key).toDate(),
        formatters: {
            scale: (key) => moment(key).format("HH:mm")
        },
        min: moment(chartDateRange.startDate).toDate(),
        max: moment(chartDateRange.endDate).toDate()
    }), [chartDateRange]);
    const chargeDischargeSecondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${(value * 0.001).toFixed(2)} kW`,
            tooltip: (value) => `${(value * 0.001).toFixed(2)} kW`
        },
        stacked: true,
    }], []);
    const soeSecondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${value}%`,
            tooltip: (value) => `${value}%`
        },
        stacked: true,
        min: 0,
        max: 100
    }], []);

    return (
        <Modal show={ show } onHide={ handleClose } size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Batterij data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-3">
                    <div className="card-body">
                        <ChartDateSwitcher
                            date={ date }
                            setDate={ onDateChange }
                        />
                    </div>
                </div>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : (
                    <React.Fragment>
                        <ChartCard title="Geladen / Ontladen">
                            { !chargeDischargeData ? (
                                <ChartLoading/>
                            ) : chargeDischargeData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <Chart
                                    options={{
                                        data: chargeDischargeData,
                                        primaryAxis,
                                        secondaryAxes: chargeDischargeSecondaryAxes
                                    }}
                                />
                            )}
                        </ChartCard>
                        <ChartCard title="Laadtoestand" height={ 300 }>
                            { !soeData ? (
                                <ChartLoading/>
                            ) : soeData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <Chart
                                    options={{
                                        data: soeData,
                                        primaryAxis,
                                        secondaryAxes: soeSecondaryAxes
                                    }}
                                />
                            )}
                        </ChartCard>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(BatteryHistoryModal);
