import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment-timezone";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartLoading from "../components/charts/ChartLoading";
import ChartNoData from "../components/charts/ChartNoData";

function ChartProductionForecast({ startDate, endDate, installationId }) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const currentHourString = useMemo(() => {
        return moment().format("YYYY-MM-DD HH");
    }, []);

    useEffect(() => {
        if(!startDate || !endDate || !installationId) {
            return;
        }
        const startDateFormat = startDate.tz("Europe/Amsterdam").toISOString();
        const endDateFormat = endDate.tz("Europe/Amsterdam").toISOString();
        setData(null);
        setError(null);
        let parameters = {
            installationId,
            startDate: startDateFormat,
            endDate: endDateFormat
        }
        axios.post("/getSolarForecast", parameters)
            .then((response) => {
                if(response.data.valid) {
                    if(!response.data.productionForecast || response.data.productionForecast.productionForecast.length === 0) {
                        setData([]);
                        return;
                    }
                    setData([{
                        label: "Verwachte solar productie",
                        data: Object.entries(response.data.productionForecast.productionForecast).map(([date, energy]) => ({
                            key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                            value: energy
                        }))
                    }]);
                } else {
                    setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            });
    }, [startDate, endDate, installationId]);

    const primaryAxis = useMemo(() => ({
        getValue: (datum) => moment(datum.key).format("HH:mm")
    }), []);
    const secondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        min: 0
    }], []);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
                getDatumStyle: (datum) => {
                    if(moment(datum.originalDatum.key).format("YYYY-MM-DD HH") !== currentHourString) {
                        return {}
                    }
                    return {
                        color: "#40bfff"
                    }
                }
            }}
        />
    )
}

export default React.memo(ChartProductionForecast);
