import React, {
    useContext,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Spinner
} from "react-bootstrap";
import {
    Link
} from "react-router-dom";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageColumnFooter,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

import Helmet from "../../components/Helmet";
import Copyright from "./Copyright";
import Logo from "../../components/Logo";

function ForgotPassword() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            setLoading(true);
            axios.post("/forgotPassword", { email })
                .then((response) => {
                    if(response.data.valid) {
                        setSuccess("Er is een email verstuurd naar " + email + " met instructies om je wachtwoord te resetten.");
                    } else {
                        setError(authenticatedUserContext.getErrorMessage(response.data.error));
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setError(authenticatedUserContext.getErrorMessage());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [authenticatedUserContext, email]);

    return (
        <AuthenticationPage>
            <Helmet/>
            <AuthenticationPageColumn>
                <Logo className="login-logo"/>
                <h3 className="text-center text-muted mt-0">
                    Wachtwoord vergeten
                </h3>
                <div className="text-center mb-4">
                    <Link to="/login" style={{ fontSize: "0.9rem" }}>
                        <i className="fa-solid fa-chevron-left mr-2"/>
                        Terug naar inloggen
                    </Link>
                </div>
                { error && (
                    <Alert variant="danger">
                        { error }
                    </Alert>
                )}
                { success ? (
                    <Alert variant="success">
                        { success }
                    </Alert>
                ) : (
                    <form onSubmit={ onSubmit }>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email"
                                disabled={ loading }
                                value={ email }
                                onChange={(event) => setEmail(event.target.value) }
                            />
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={ loading }>
                                { loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Verstuur
                            </button>
                        </div>
                    </form>
                )}

                <AuthenticationPageColumnFooter>
                    <a href="https://gridsense.nl">
                        GridSense website
                    </a>
                </AuthenticationPageColumnFooter>
            </AuthenticationPageColumn>

            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    )
}

export default React.memo(ForgotPassword);
