import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import RelaySettingsCard from "./RelaySettingsCard";
import RelayIndexSettingsModal from "./RelayIndexSettingsModal";
import RelayStateSetting from "./RelayStateSetting";
import DateFormatter from "../../../../../components/formatters/DateFormatter";

function RelaySettingsModal({ relay, onSaved, show, handleClose }) {
    const [desiredStateError, setDesiredStateError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [showRelayIndexSettingsModal, setShowRelayIndexSettingsModal] = useState(false);
    const [relayIndexSettingsIndex, setRelayIndexSettingsIndex] = useState(null);

    const setDesiredState = useCallback(async (relayIndex, desiredState) => {
        setDesiredStateError(null);
        setLoading(true);
        try {
            await axios.post("/setRelayDesiredState", {
                relayId: relay.id,
                relayIndex,
                desiredState: desiredState === null ? "null" : (desiredState ? 1 : 0),
            });
            onSaved?.();
        } catch(requestError) {
            setDesiredStateError("Er is iets fout gegaan. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [relay, onSaved]);

    const onClickEdit = useCallback((index) => {
        setRelayIndexSettingsIndex(index);
        setShowRelayIndexSettingsModal(true);
    }, []);

    if(!relay) {
        return null;
    }
    return (
        <Modal show={ show } onHide={ handleClose } size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Relais instellingen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RelayIndexSettingsModal
                    show={ showRelayIndexSettingsModal }
                    handleClose={ () => setShowRelayIndexSettingsModal(false) }
                    relay={ relay }
                    index={ relayIndexSettingsIndex }
                    onSaved={ onSaved }
                />

                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">
                            Relais status
                        </h5>
                        {desiredStateError && (
                            <Alert variant="danger">{desiredStateError}</Alert>
                        )}
                        <div
                            className="d-flex flex-row flex-wrap"
                            style={{ gap: "0.25rem" }}
                        >
                            {relay.currentState.map((state, index) => (
                                <RelayStateSetting
                                    key={ index }
                                    index={ index }
                                    state={ state }
                                    desiredState={ relay.desiredState?.[index] }
                                    onSet={ (desiredState) => setDesiredState(index, desiredState) }
                                    loading={ loading }
                                />
                            ))}
                        </div>
                        <div className="mt-2 text-muted">
                            <small>
                                Laatst bijgewerkt: <DateFormatter date={ relay.lastSeenDate }/>
                            </small>
                        </div>
                    </div>
                </div>
                <h5>
                    Instellingen per relais
                </h5>
                <div className="row">
                    {relay.settings.map((settings) => (
                        <div
                            key={settings.index}
                            className="col-lg-6"
                        >
                            <RelaySettingsCard
                                settings={settings}
                                onClickEdit={() => onClickEdit(settings.index)}
                            />
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(RelaySettingsModal);
